






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import userRoles from "@/constants/userRoles";
import userGenders from "@/constants/userGenders";
import DataContainer from "@vuetifyx/common/DataContainer";
import userHts from "@/constants/userHts";
import moment from "moment";
import AvgTvDuration from "./users/partials/AvgTvDuration.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "disabled",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý người dùng",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              roles: {
                type: "select",
                attrs: {
                  label: "Vai trò",
                  items: [{ value: "", text: "Trống" }].concat(makeSelectOptions(userRoles)),
                },
                rules: {
                  equal_to: {},
                },
              },
              ht: {
                type: "select",
                attrs: {
                  label: "Hình thức",
                  items: [{ value: "", text: "Trống" }].concat(makeSelectOptions(userHts)),
                },
                rules: {
                  equal_to: {},
                },
              },
              teamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: [
                    { text: "Bình thường", value: false },
                    { text: "Khóa", value: true },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
              mktDomains: {
                type: "textMultiple",
                attrs: {
                  label: "MKT Domains",
                },
                rules: {
                  in: {},
                },
              },
              mktPlatformIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              dob: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày sinh (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày sinh (kết thúc)",
                    },
                  },
                },
              },
              personalEmail: {
                attrs: {
                  label: "Email cá nhân",
                },
                rules: {
                  equal_to: {},
                },
              },
              gender: {
                attrs: {
                  label: "Giới tính",
                  items: makeSelectOptions(userGenders),
                },
                rules: {
                  equal_to: {},
                },
              },
              phoneNumber: {
                attrs: {
                  label: "Số điện thoại",
                },
                rules: {
                  equal_to: {},
                },
              },
              address: {
                attrs: {
                  label: "Địa chỉ thường trú",
                },
                rules: {
                  equal_to: {},
                },
              },
              currentAddress: {
                attrs: {
                  label: "Địa chỉ hiện tại",
                },
                rules: {
                  equal_to: {},
                },
              },
              idNumber: {
                attrs: {
                  label: "CCCD",
                },
                rules: {
                  equal_to: {},
                },
              },
              idNumberIssueTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày cấp CCCD (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày cấp CCCD (kết thúc)",
                    },
                  },
                },
              },
              idNumberIssuePlace: {
                attrs: {
                  label: "Nơi cấp CCCD",
                },
                rules: {
                  equal_to: {},
                },
              },
              atm: {
                attrs: {
                  label: "STK ATM",
                },
                rules: {
                  equal_to: {},
                },
              },
              atmBranch: {
                attrs: {
                  label: "Chi nhánh ATM",
                },
                rules: {
                  equal_to: {},
                },
              },
              atmName: {
                attrs: {
                  label: "Tên chủ TK ATM",
                },
                rules: {
                  equal_to: {},
                },
              },
              facebookUrl: {
                attrs: {
                  label: "Link Facebook",
                },
                rules: {
                  equal_to: {},
                },
              },
              phoneNumber2: {
                attrs: {
                  label: "SĐT người thân",
                },
                rules: {
                  equal_to: {},
                },
              },
              zaloName: {
                attrs: {
                  label: "Tên Zalo",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            index: {
              text: "STT",
              options: {
                index: true,
              },
            },
            email: {
              sortable: true,
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
              sortable: true,
              hidden: true,
            },
            roles: {
              text: "Vai trò",
              options: {
                labels: true,
                labelItems(value) {
                  return userRoles[value];
                },
              },
            },
            ht: {
              text: "Hình thức",
              options: {
                label(value) {
                  return userHts[value];
                },
              },
            },
            department: {
              text: "Phòng",
              options: {
                subProp: "team.department.name",
                label: true,
              },
            },
            team: {
              text: "Team",
              options: {
                subProp: "team.name",
                label: true,
                sortBy: "teamId",
              },
            },
            disabled: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return (value && "Khóa") || "Bình thường";
                },
                attrs(value) {
                  return {
                    color: value ? "red" : "green",
                  };
                },
              },
            },
            mktDomains: {
              text: "MKT Domains",
              options: {
                labels: true,
              },
            },
            mktPlatforms: {
              text: "Nền tảng MKT",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            chatPageItems: {
              text: "Chat Page",
              hidden: true,
              options: {
                labels: true,
                labelItems(value) {
                  return `${value.id} - ${value.link}`;
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tvTime: {
              text: "Ngày thử việc",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            ctTime: {
              text: "Ngày làm chính thức",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            pnTime: {
              text: "Ngày lên phó nhóm",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            ldTime: {
              text: "Ngày lên leader",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            tpTime: {
              text: "Ngày lên trưởng phòng",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            nvTime: {
              text: "Ngày dừng làm việc",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            tvDuration: {
              text: "Thời gian thử việc",
              hidden: true,
              options: {
                preTransform(_, item) {
                  if (item.ctTime && item.tvTime) {
                    const duration = moment(item.ctTime).diff(item.tvTime, "days");
                    return `${duration} ngày`;
                  }
                },
              },
            },
            dob: {
              text: "Ngày sinh",
              options: {
                filter: "dateTime",
              },
            },
            personalEmail: {
              text: "Email cá nhân",
            },
            gender: {
              text: "Giới tính",
              options: {
                label(value) {
                  return userGenders[value];
                },
              },
            },
            phoneNumber: {
              text: "Số điện thoại",
            },
            address: {
              text: "Địa chỉ thường trú",
            },
            currentAddress: {
              text: "Địa chỉ hiện tại",
            },
            idNumber: {
              text: "CCCD",
            },
            idNumberIssueTime: {
              text: "Ngày cấp CCCD",
              options: {
                filter: "dateTime",
              },
            },
            idNumberIssuePlace: {
              text: "Nơi cấp CCCD",
            },
            atm: {
              text: "STK ATM",
            },
            atmBranch: {
              text: "Chi nhánh ATM",
            },
            atmName: {
              text: "Tên chủ TK ATM",
            },
            facebookUrl: {
              text: "Link Facebook",
            },
            phoneNumber2: {
              text: "SĐT người thân",
            },
            zaloName: {
              text: "Tên Zalo",
            },
            action: {
              text: "Hành động",
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            console.log(data);
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("users", "import", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("users", `@/export`, findAllOptions);
                },
              },
            },
            importMktDomains: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import MKT Domains",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import MKT Domains" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import MKT Domains",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("users", "importMktDomains", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            importChatPages: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import Chat Pages",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import Chat Pages" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import Chat Pages",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("users", "importChatPages", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            viewAvgTvDuration: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xem TGTVTB",
              },
              target: {
                tooltip: { content: { text: "Thời gian thử việc trung bình" } },
                dialog: {
                  content: {
                    contentIsNotDefaultComponent: true,
                    toolbar: {
                      title: "Thời gian thử việc trung bình của toàn công ty",
                    },
                    content: {
                      type: AvgTvDuration,
                    },
                    buttons: {
                      cancel: {},
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: "email",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              moveTeam: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-account-group",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Chuyển team",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Chuyển team",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.email;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextTeamId: item.nextTeamId,
                            nextTeamStartTime: item.nextTeamStartTime,
                            nextTeamMktPlatformId: item.nextTeamMktPlatformId,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextTeamId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Team",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("teams", "findAll", {
                                                limit: -1,
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    nextTeamStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển team",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                    nextTeamMktPlatformId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Nền tảng MKT",
                                        "item-value": "_id",
                                        "item-text": "name",
                                        required: true,
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                                                limit: -1,
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                      ext: {
                                        condition(form) {
                                          const dialog = form.context();
                                          const { item } = dialog.context().context();
                                          return item.teamItems?.length >= 2;
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "users",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextTeamId: data.nextTeamId,
                                        nextTeamStartTime: data.nextTeamStartTime,
                                        nextTeamMktPlatformId: data.nextTeamMktPlatformId,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              resetLoginDevices: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-refresh",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Reset thiết bị đăng nhập",
                    },
                  },
                  confirm: {
                    content: {
                      content: {
                        html: "Xác nhận reset thiết bị đăng nhập?",
                      },
                    },
                    on: {
                      async xYes({ self: dialog }) {
                        const btn = dialog.context();
                        const { item, dataTable } = btn.context();
                        const result = await dataTable.execute(
                          async () => {
                            return await coreApiClient.call("loginDevices", "deleteByUser", {
                              userId: item._id,
                            });
                          },
                          undefined,
                          {
                            disableLoading: true,
                            willRefresh: true,
                          }
                        );
                        if (result) {
                          dialog.hide();
                        }
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.orderItems = [];
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
              options.orderBy = undefined;
              options.orderType = undefined;
            }
            options.orderItems.push({
              field: "disabled",
              type: "asc",
            });
            options.orderItems.push({
              field: "createdTime",
              type: "desc",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("users", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("users", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "users",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("users", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              email: {
                attrs: {
                  required: true,
                },
              },
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              roles: {
                type: "selectMultiple",
                attrs: {
                  label: "Vai trò",
                  items: makeSelectOptions(userRoles),
                  required: true,
                },
                content: {
                  rules: {
                    custom: [
                      async (value) => {
                        return value && value.length;
                      },
                    ],
                  },
                },
              },
              ht: {
                type: "select",
                attrs: {
                  label: "Hình thức",
                  items: makeSelectOptions(userHts),
                  required: true,
                },
              },
              teamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: [
                    { text: "Bình thường", value: false },
                    { text: "Khóa", value: true },
                  ],
                },
              },
              mktDomains: {
                type: "textMultiple",
                attrs: {
                  label: "MKT Domains",
                },
              },
              chatPageItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Chat Pages",
                  xOptions: {
                    content: {
                      itemLabel: (item) => `${item.id || ""} - ${item.link || ""}`,
                      itemProperties: {
                        id: {
                          attrs: {
                            label: "ID",
                            required: true,
                          },
                        },
                        link: {
                          attrs: {
                            label: "Link",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                          },
                        },
                      },
                    },
                  },
                },
              },
              teamItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Teams",
                  xOptions: {
                    content: {
                      itemProperties: {
                        teamId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Team",
                            required: true,
                            "item-value": "_id",
                            "item-text": "name",
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call("teams", "findAll", {
                                    limit: -1,
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        mktPlatformId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Nền tảng MKT",
                            required: true,
                            "item-value": "_id",
                            "item-text": "name",
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                                    limit: -1,
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày chuyển team",
                            required: true,
                          },
                        },
                        main: {
                          type: "boolean",
                          attrs: {
                            label: "Team chính",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 3,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 8,
                },
              },
              belongsToTeamItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Lịch sử chuyển team",
                  xOptions: {
                    content: {
                      itemProperties: {
                        teamId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Team",
                            required: true,
                            "item-value": "_id",
                            "item-text": "name",
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call("teams", "findAll", {
                                    limit: -1,
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày chuyển team",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                          },
                        },
                      },
                    },
                  },
                },
              },
              salaryItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Lịch sử lương cứng",
                  xOptions: {
                    content: {
                      itemProperties: {
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày bắt đầu",
                            required: true,
                          },
                        },
                        value: {
                          type: "number",
                          attrs: {
                            label: "Lương cứng",
                            required: true,
                          },
                        },
                        description: {
                          type: "textLarge",
                          attrs: {
                            label: "Mô tả",
                          },
                          ext: {
                            colLength: 12,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                          },
                        },
                      },
                    },
                  },
                },
              },
              mktPlatformIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              password: {
                type: "password",
                attrs: {
                  label: "Mật khẩu",
                },
              },
              loginDeviceLimit: {
                type: "integer",
                attrs: {
                  label: " Số thiết bị đăng nhập tối đa",
                },
              },
              loginDeviceLimitMobile: {
                type: "integer",
                attrs: {
                  label: " Số thiết bị đăng nhập tối đa (Mobile)",
                },
              },
              tvTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày thử việc",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              ctTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày lên chính thức",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              pnTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày lên phó nhóm",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              ldTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày lên leader",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              tpTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày lên trưởng phòng",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              nvTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày nghỉ việc",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              dob: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày sinh",
                },
              },
              personalEmail: {
                attrs: {
                  label: "Email cá nhân",
                },
              },
              gender: {
                attrs: {
                  label: "Giới tính",
                  items: makeSelectOptions(userGenders),
                },
              },
              phoneNumber: {
                attrs: {
                  label: "Số điện thoại",
                },
              },
              address: {
                attrs: {
                  label: "Địa chỉ thường trú",
                },
              },
              currentAddress: {
                attrs: {
                  label: "Địa chỉ hiện tại",
                },
              },
              idNumber: {
                attrs: {
                  label: "CCCD",
                },
              },
              idNumberIssueTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày cấp CCCD",
                },
              },
              idNumberIssuePlace: {
                attrs: {
                  label: "Nơi cấp CCCD",
                },
              },
              atm: {
                attrs: {
                  label: "STK ATM",
                },
              },
              atmBranch: {
                attrs: {
                  label: "Chi nhánh ATM",
                },
              },
              atmName: {
                attrs: {
                  label: "Tên chủ TK ATM",
                },
              },
              facebookUrl: {
                attrs: {
                  label: "Link Facebook",
                },
              },
              phoneNumber2: {
                attrs: {
                  label: "SĐT người thân",
                },
              },
              zaloName: {
                attrs: {
                  label: "Tên Zalo",
                },
              },
            },
            insertForm: userManager.checkRole(["kt"]) && {
              content: {
                colLength: 4,
                fieldNames: [
                  "email",
                  "name",
                  "roles",
                  "teamId",
                  "ht",
                  "disabled",
                  "mktDomains",
                  "chatPageItems",
                  "mktPlatformIds",
                  "description",
                  "password",
                  "loginDeviceLimit",
                  "loginDeviceLimitMobile",
                  "tvTime",
                  "ctTime",
                  "pnTime",
                  "ldTime",
                  "tpTime",
                  "nvTime",
                  "teamItems",
                  "belongsToTeamItems",
                  "salaryItems",
                  "dob",
                  "personalEmail",
                  "gender",
                  "phoneNumber",
                  "address",
                  "currentAddress",
                  "idNumber",
                  "idNumberIssueTime",
                  "idNumberIssuePlace",
                  "atm",
                  "atmBranch",
                  "atmName",
                  "facebookUrl",
                  "phoneNumber2",
                  "zaloName",
                ],
              },
            },
            editForm: userManager.checkRole(["kt"]) && {
              content: {
                colLength: 4,
                fieldNames: [
                  "email",
                  "name",
                  "roles",
                  "teamId",
                  "ht",
                  "disabled",
                  "mktDomains",
                  "chatPageItems",
                  "mktPlatformIds",
                  "description",
                  "password",
                  "loginDeviceLimit",
                  "loginDeviceLimitMobile",
                  "tvTime",
                  "ctTime",
                  "pnTime",
                  "ldTime",
                  "tpTime",
                  "nvTime",
                  "teamItems",
                  "belongsToTeamItems",
                  "salaryItems",
                  "dob",
                  "personalEmail",
                  "gender",
                  "phoneNumber",
                  "address",
                  "currentAddress",
                  "idNumber",
                  "idNumberIssueTime",
                  "idNumberIssuePlace",
                  "atm",
                  "atmBranch",
                  "atmName",
                  "facebookUrl",
                  "phoneNumber2",
                  "zaloName",
                ],
              },
            },
            delete: {
              confirmDisplayField: "email",
            },
          },
        },
      },
    };
  },
});
