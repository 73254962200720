









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
export default Vue.extend({
  data() {
    return {
      value: 0,
      loading: false
    };
  },
  async created() {
    this.loading = true;
    const data = await coreApiClient.call("users", "getStatistic", {
      payload: JSON.stringify({
        findRequest: {
          filters: [
            {
              key: "tvTime",
              operator: "greater_than",
              value: 0
            },
            {
              key: "ctTime",
              operator: "greater_than",
              value: 0
            }
          ]
        },
        aggregations: {
          value: {
            type: "avg",
            magicField: {
              funcName: "subtract",
              fields: ["ctTime", "tvTime"]
            }
          }
        }
      })
    });
    this.value = Math.floor((data.value || 0) / 86400 / 1000);
    this.loading = false;
  }
});
